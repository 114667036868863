import { mapState } from 'vuex'
import dayjs from 'dayjs'
import FormatItem from '@/components/common/format-item/index.vue'
import EmptyList from '@/components/common/empty-list/index.vue'
import Rmb from '@/components/common/rmb/index.vue'
import { getDiscountPrice } from '@/utils/common'
import { ORDER_STATE_NAME } from '@/constants/common'
import Event from '@/constants/event-bus'
import { debounce } from 'lodash'
import OrderDetail from '@/components/order/order-detail/index.vue'
import IndexTabbar from '@/components/common/index-tabbar/index.vue'

const LIMIT = 10

/**
 * 订单列表
 */
export default {
  name: 'order-list',

  components: {
    FormatItem,
    EmptyList,
    Rmb,
    OrderDetail,
    IndexTabbar
  },

  data () {
    return {
      ORDER_STATE_NAME,
      types: [
        { state: -1, name: '全部', noMore: false }
      ],
      currentIndex: 0, // 当前状态索引
      orders: {}, // 用以保存每个子页面的状态
      loading: false, // 加载中
      stateLoaded: false
    }
  },

  computed: {
    ...mapState({
      brandId: state => state.global.brandId,
      userId: state => state.user.user.id,
      orderStates: state => state.order.orderStates
    })
  },

  watch: {
    $route: {
      handler (newRoute, oldRoute) {
        if (newRoute.name === 'order-list' && oldRoute.name === 'order-list') { // 注：当本页面更新（意味着切换Tab）时，才需要刷新列表。跳转到其它页面就不必了
          this.refresh()
        }
      }
    }
  },

  methods: {
    getDiscountPrice,

    // 点击跳转到快递查询单号
    toExpress (item, e) {
      e.preventDefault();
      window.location.href = `https://m.kuaidi100.com/result.jsp?nu=${item.trackingNumber}&com=${item.com}`;
    },
    // 切换 Tab
    onChangeTab (newIndex) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          state: this.types[newIndex].state
        }
      })
    },

    // 初始化
    async init () {
      await this.$store.dispatch('order/getOrderStates')
      this.stateLoaded = true
      const tempStats = this.orderStates.filter(st => st.name && st.name != '待审核' && st.name != '待财务审核')
      this.types = tempStats.map(s => { return { ...s, noMore: false } })
      const { state = -1 } = this.$route.query
      const cIndex = this.types.findIndex(item => String(item.state) === String(state))
      this.currentIndex = cIndex > -1 ? cIndex : 0
      await this.refresh()
    },

    // 刷新列表
    async refresh () {
      const state = this.types[this.currentIndex].state // 当前状态
      const orders = await this.getData(0)
      this.$set(this.orders, state, orders)
    },

    // 加载更多
    // 使用`debounce`是因为同一种滚动会触发多次，可能是van-list的问题？不确定
    loadMore: debounce(async function () {
      const state = this.types[this.currentIndex].state // 当前状态
      const oldList = this.orders[state]
      const orders = await this.getData(oldList.length)
      this.$set(this.orders, state, oldList.concat(orders))
    }, 200),

    // 加载数据总方法
    async getData (offset) {
      const state = this.types[this.currentIndex].state // 当前状态

      try {
        this.types[this.currentIndex].loading = true
        // const orders = await this.$store.dispatch('order/loadOrdersv1', {
        const orders = await this.$store.dispatch('order/loadOrders', {
          brandId: this.brandId,
          state,
          limit: LIMIT,
          offset,
          userId: this.userId
        })
        if (orders.length < LIMIT) { // 本页数量少于要求数量，说明是最后一页了
          this.types[this.currentIndex].noMore = true
        }
        return orders.map(item => {
          const createdAt = dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')
          return {
            ...item,
            createdAt
          }
        })
      } catch (e) {
        console.error(e)
        return []
      } finally {
        this.types[this.currentIndex].loading = false
      }
    },

    // 跳转订单详情
    showOrder (order) {
      this.$router.push({
        name: 'order-detail',
        params: {
          id: order.id
        }
      })
    }
  },

  async created () {
    this.$bus.$on(Event.orderList.refresh, this.refresh)
    await this.init()
  },

  destroyed () {
    this.$bus.$off(Event.orderList.refresh, this.refresh)
  },
}